var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.role ? 'container_is_auth' : 'container_no_auth'},[(!_vm.role)?_c('Nav'):_vm._e(),(!_vm.loading)?_c('div',{class:_vm.role ? 'child_is_auth' : 'child_no_auth'},[(_vm.surveys)?_c('div',[_c('div',[_c('v-card',{staticClass:"radius-card",attrs:{"elevation":"0"}},[_c('div',{class:_vm.surveys.kategori == 'Psikologi Klinis'
                ? 'a__'
                : _vm.surveys.kategori == 'Psikologi Pendidikan'
                ? 'b__'
                : 'c__',staticStyle:{"height":"15px","width":"100%"}}),_c('div',{staticClass:"pa-5 mb-5"},[_c('h1',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.surveys.judul))]),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.surveys.keterangan))])])]),(_vm.profile)?_c('v-card',{staticClass:"radius-card py-3 px-5 mb-3",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('p',{staticClass:"mt-2"},[_vm._v("Nama lengkap")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.profile.nama_lengkap)?_c('v-text-field',{attrs:{"label":"Nama Lengkap","dense":""},model:{value:(_vm.profile.nama_lengkap),callback:function ($$v) {_vm.$set(_vm.profile, "nama_lengkap", $$v)},expression:"profile.nama_lengkap"}}):_c('v-text-field',{attrs:{"label":"Nama Lengkap","dense":""},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('p',{staticClass:"mt-2"},[_vm._v("Email")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":"Email","dense":""},model:{value:(_vm.profile.email),callback:function ($$v) {_vm.$set(_vm.profile, "email", $$v)},expression:"profile.email"}})],1)],1)],1):_vm._e(),_c('div',[_c('div',_vm._l((_vm.surveys.relationships[1].jawaban.data[0]
                .jawaban),function(question){return _c('v-card',{key:("quest-" + (question.id)),staticClass:"radius-card px-5 pt-5 mb-5",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('h3',[_vm._v(_vm._s(question.soal))])])],1),_c('div',{staticClass:"py-3"},[_c('v-radio-group',{model:{value:(question.jawaban),callback:function ($$v) {_vm.$set(question, "jawaban", $$v)},expression:"question.jawaban"}},_vm._l((question.pilihan),function(val,key){return _c('v-radio',{key:key,attrs:{"disabled":"","label":val,"value":key,"id":("radio-" + (question.id) + "-" + key)}})}),1)],1)],1)}),1)]),(_vm.surveys.status == 0)?_c('div',[(!_vm.loadSave)?_c('v-btn',{attrs:{"depressed":"","color":_vm.surveys.kategori == 'Psikologi Klinis'
                ? 'a__'
                : _vm.surveys.kategori == 'Psikologi Pendidikan'
                ? 'b__'
                : 'c__',"dark":""},on:{"click":_vm.saveAnswer}},[_c('b',[_vm._v("Kirim")])]):_vm._e(),(_vm.loadSave)?_c('v-btn',{attrs:{"depressed":"","color":_vm.surveys.kategori == 'Psikologi Klinis'
                ? 'a__'
                : _vm.surveys.kategori == 'Psikologi Pendidikan'
                ? 'b__'
                : 'c__',"dark":""}},[_c('b',[_vm._v("Loading..")])]):_vm._e()],1):_vm._e(),(_vm.surveys.user_id == _vm.id)?_c('v-btn',{staticClass:"mt-2",attrs:{"depressed":"","color":"cyan","dark":"","to":("/survey/" + (_vm.surveys.id) + "/update")}},[_c('b',[_vm._v("Edit Survey")])]):_vm._e()],1)]):_vm._e()]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"_100top pa-3 _full-w"},[_c('h3',[_vm._v("Loading...")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }