<template>
  <div :class="role ? 'container_is_auth' : 'container_no_auth'">
    <Nav v-if="!role" />
    <div :class="role ? 'child_is_auth' : 'child_no_auth'" v-if="!loading">
      <div v-if="surveys">
        <div>
          <v-card class="radius-card" elevation="0">
            <div
              :class="
                surveys.kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : surveys.kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__'
              "
              style="height: 15px; width: 100%"
            ></div>
            <div class="pa-5 mb-5">
              <h1 class="mb-2">{{ surveys.judul }}</h1>
              <p class="ma-0">{{ surveys.keterangan }}</p>
            </div>
          </v-card>
          <v-card
            class="radius-card py-3 px-5 mb-3"
            elevation="0"
            v-if="profile"
          >
            <v-row>
              <v-col cols="12" md="3"><p class="mt-2">Nama lengkap</p></v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-if="profile.nama_lengkap"
                  label="Nama Lengkap"
                  dense
                  v-model="profile.nama_lengkap"
                ></v-text-field>
                <v-text-field
                  v-else
                  label="Nama Lengkap"
                  dense
                  v-model="fullname"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3"><p class="mt-2">Email</p></v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  label="Email"
                  dense
                  v-model="profile.email"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
          <div>
            <div>
              <v-card
                class="radius-card px-5 pt-5 mb-5"
                v-for="question in surveys.relationships[1].jawaban.data[0]
                  .jawaban"
                :key="`quest-${question.id}`"
                elevation="0"
              >
                <v-row>
                  <v-col cols="12" md="11">
                    <h3>{{ question.soal }}</h3>
                  </v-col>
                </v-row>
                <div class="py-3">
                  <v-radio-group v-model="question.jawaban">
                    <v-radio
                      disabled
                      v-for="(val, key) in question.pilihan"
                      :key="key"
                      :label="val"
                      :value="key"
                      :id="`radio-${question.id}-${key}`"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-card>
            </div>
          </div>
          <div v-if="surveys.status == 0">
            <v-btn
              depressed
              :color="
                surveys.kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : surveys.kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__'
              "
              dark
              v-if="!loadSave"
              @click="saveAnswer"
            >
              <b>Kirim</b>
            </v-btn>

            <v-btn
              depressed
              :color="
                surveys.kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : surveys.kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__'
              "
              dark
              v-if="loadSave"
            >
              <b>Loading..</b>
            </v-btn>
          </div>
          <v-btn
            v-if="surveys.user_id == id"
            depressed
            color="cyan"
            dark
            :to="`/survey/${surveys.id}/update`"
            class="mt-2"
          >
            <b>Edit Survey</b>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="_100top pa-3 _full-w" v-if="loading">
      <h3>Loading...</h3>
    </div>
  </div>
</template>

<script>
// import Swal from "sweetalert2";
import { mapState } from "vuex";
import Nav from "../../components/General/Nav.vue";

export default {
  components: { Nav },
  name: "viewSURVEY",
  computed: {
    ...mapState({
      surveys: (state) => state.survey.survey_byid,
      env: (state) => state.API_URL,
      id: (state) => state.id,
      role: (state) => state.role,
      profile: (state) => state.psycholog.myProfile,
    }),
  },
  data() {
    return {
      loading: false,
      fullname: "",
      email: "",
      answer: [],
      loadSave: false,
      isAnswered: false,
    };
  },

  mounted() {
    this.fetch();
    // window.onbeforeunload = function () {
    //   return "Jawaban anda belum tersimpan";
    // };
  },
  // eslint-disable-next-line no-unused-vars
  // beforeRouteLeave(to, from, next) {
  //   if (!this.isAnswered) {
  //     Swal.fire({
  //       //validation if user want to navigate route
  //       title: "Yakin keluar survey?",
  //       text: "Jawaban anda tidak akan disimpan",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Ya",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         next();
  //       }
  //     });
  //   } else {
  //     next();
  //   }
  // },
  methods: {
    fetch() {
      this.loading = true; //set loading to true
      let id = this.$route.params.id;
      console.log(this.$route.params.visible);
      this.$store.dispatch("survey/getSurvey", id).then(() => {
        this.loading = false; //if request api get succes set loading to false
      });
    },
  },
};
</script>

<style scoped>
.slash__ {
  height: 15px;
  width: 100%;
}
.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 120px;
  padding: 10px;
  margin: 90px auto;
  width: 70%;
}
.child_no_auth {
  margin-top: 100px !important;
  padding: 10px;
  margin: 0 auto;
  width: 70%;
}
.custom-radio {
  width: 1.3em !important;
  height: 1.3em !important;
  vertical-align: top !important;
  margin-right: 1% !important;
  margin-top: 0.3% !important;
}
@media (max-width: 768px) {
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 369px) {
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
}
</style>
